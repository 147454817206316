import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import CatalogueRoll from '../components/CatalogueRoll';

import ContentContainer from '../components/Layouts/ContentContainer';
import Section from '../components/Layouts/ContentLayout/Section';

// Export so CMS Preview page can use it.
export const CategoryPageTemplate = ({ cards, details }) => {
  return (
    <div>
      <ContentContainer>
        <div className="columns">
          <Section
            title={details.name}
            description={details.description}
          ></Section>
        </div>
        <CatalogueRoll cards={cards}></CatalogueRoll>
        {(cards && cards.length > 0) || (
          <p>There is currently no products in this category.</p>
        )}
      </ContentContainer>
    </div>
  );
};

const CategoryPage = ({ data }) => {
  const { nodes } = data.products;
  const details = data.brandDetail.frontmatter;
  const cards = nodes.map((node) => {
    const frontmatter = node.frontmatter;
    return {
      title: frontmatter.name,
      subtitle: 'Product',
      image: frontmatter.featuredimage,
      link: node.fields.slug,
    };
  });

  return (
    <Layout>
      <CategoryPageTemplate
        cards={cards}
        details={details}
      ></CategoryPageTemplate>
    </Layout>
  );
};

export default CategoryPage;

export const pageQuery = graphql`
  query BrandPage($id: String!, $brand: String!) {
    brandDetail: markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
        name
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: { queryKey: { eq: "products" }, brand: { eq: $brand } }
      }
    ) {
      nodes {
        frontmatter {
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          brand
        }
        fields {
          slug
        }
      }
    }
  }
`;
